<template>
  <header class="o-header-default">
    <div class="container">
      <div class="o-header-default__container">
        <div class="o-header-default__left-column">
          <a href="/" class="o-header-default__logo-link">
            <svg
              width="200"
              height="31"
              viewBox="0 0 200 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="o-header-default__logo-image"
            >
              <path
                d="M62 15.8127C62 24.1079 48.1208 30.8325 31 30.8325C13.8792 30.8325 0 24.1079 0 15.8127C0 7.51755 13.8792 0.792969 31 0.792969C48.1208 0.792969 62 7.51755 62 15.8127Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M52.6732 25.9068C58.2327 23.2132 61.4259 19.6058 61.4259 15.8127C61.4259 12.0197 58.2327 8.41229 52.6732 5.71865C47.1562 3.04563 39.4943 1.37626 31 1.37626C22.5057 1.37626 14.8438 3.04563 9.32678 5.71865C3.76726 8.41229 0.574074 12.0197 0.574074 15.8127C0.574074 19.6058 3.76726 23.2132 9.32678 25.9068C14.8438 28.5798 22.5057 30.2492 31 30.2492C39.4943 30.2492 47.1562 28.5798 52.6732 25.9068ZM31 30.8325C48.1208 30.8325 62 24.1079 62 15.8127C62 7.51755 48.1208 0.792969 31 0.792969C13.8792 0.792969 0 7.51755 0 15.8127C0 24.1079 13.8792 30.8325 31 30.8325Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M51.5891 24.7877C56.8829 22.3709 59.876 19.1525 59.876 15.8128C59.876 12.4731 56.8829 9.25471 51.5891 6.83789C46.3494 4.44581 39.0706 2.95121 31 2.95121C22.9295 2.95121 15.6507 4.44581 10.411 6.83789C5.11717 9.25471 2.12412 12.4731 2.12412 15.8128C2.12412 19.1525 5.11717 22.3709 10.411 24.7877C15.6507 27.1798 22.9295 28.6744 31 28.6744C39.0706 28.6744 46.3494 27.1798 51.5891 24.7877ZM31 29.2577C47.2648 29.2577 60.4501 23.2382 60.4501 15.8128C60.4501 8.3874 47.2648 2.36792 31 2.36792C14.7353 2.36792 1.55005 8.3874 1.55005 15.8128C1.55005 23.2382 14.7353 29.2577 31 29.2577Z"
                fill="#44456B"
              />
              <path
                d="M60.2465 17.4019C58.5246 24.0784 46.0872 29.2578 31 29.2578C14.7353 29.2578 1.55005 23.2383 1.55005 15.8129C1.55005 15.8065 1.55006 15.8 1.55008 15.7935C4.87354 16.9612 10.5456 18.2948 18.9445 18.1769C26.1681 18.0754 28.9017 17.3481 31.5879 16.6335C34.3773 15.8914 37.1156 15.1628 44.7778 15.1628C51.2773 15.1628 56.4582 16.2112 60.2465 17.4019Z"
                fill="#44456B"
              />
              <path
                d="M12.3356 8.4718L8.5979 19.8651H10.934L10.1553 20.8145H16.2291L15.1389 22.2386H26.8194L26.3521 21.7639H29.7784L29.3112 21.2892H31.0243L28.6882 15.1179L25.8849 14.0102L21.5242 2.61693L18.2537 0.559814L15.4504 7.52236L12.3356 8.4718Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.1165 0.130371L21.757 2.4202L26.1067 13.7848L28.9106 14.8927L31.4423 21.5808H30.0042L30.4714 22.0555H27.0452L27.5124 22.5302H14.5521L15.6423 21.106H9.5425L10.3212 20.1566H8.19971L12.1105 8.23585L15.2367 7.28293L18.1165 0.130371ZM26.1264 21.9469L25.6592 21.4722H29.0855L28.6183 20.9975H30.6064L28.4659 15.3428L25.6632 14.2354L21.2916 2.81346L18.391 0.989056L15.6642 7.76159L12.5609 8.70754L8.99619 19.5733H11.5469L10.7682 20.5227H16.816L15.7259 21.9469H26.1264Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1322 13.0913L21.2881 13.0181L23.6242 17.2905L23.4737 17.3755L21.7004 14.1324L23.1624 18.0935L23.0011 18.1549L21.1322 13.0913ZM14.8539 14.9398L15.3545 16.2961H16.7471L17.5504 17.7653L17.3999 17.8502L16.6458 16.4711H15.2351L14.8013 15.2956L10.6908 20.7095L10.5544 20.6026L14.8539 14.9398ZM26.8823 18.8556L29.2184 21.3874L29.0927 21.5071L26.7566 18.9753L26.8823 18.8556ZM18.0664 18.9708L16.6648 20.7115L16.5315 20.6007L17.9331 18.86L18.0664 18.9708ZM24.235 19.6471L26.2596 21.8624L26.1334 21.9815L24.1088 19.7662L24.235 19.6471Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2332 4.17605L20.4041 4.1604L21.027 7.64167L20.8576 7.67298L20.4054 5.14637V7.49908H20.2332V4.17605ZM14.7876 9.68441L15.6414 7.31081L15.8031 7.37088L14.9494 9.74447L14.7876 9.68441ZM18.0529 11.203L17.2863 13.5399L17.1229 13.4845L18.0573 10.6362L18.2251 10.6639V13.1957H18.0529V11.203Z"
                fill="#44456B"
              />
              <path
                d="M39.2668 12.1673C37.6284 12.1673 36.2241 10.7843 36.2241 9.07582C36.2241 7.3674 37.5504 5.98438 39.2668 5.98438C40.905 5.98438 42.3093 7.3674 42.3093 9.07582C42.3093 10.7843 40.9831 12.1673 39.2668 12.1673Z"
                fill="#F14343"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M191.131 18.3079C191.105 21.1831 196.621 20.494 196.253 17.6188L199.879 17.595C199.852 21.4207 197.331 22.1098 193.495 22.1335C190.527 22.1573 186.586 21.5157 186.875 18.1178C187.269 15.4564 189.502 15.1951 190.474 14.8862C191.919 14.6961 193.364 14.601 194.835 14.5535C196.28 14.2208 195.649 13.1753 195.649 12.9852C194.809 11.3219 190.422 11.5833 190.579 14.0545L186.98 14.0783C187.007 10.2526 189.529 9.5635 193.364 9.53974C197.015 9.49221 199.879 10.1299 199.905 13.7693C199.918 15.599 197.657 16.578 195.965 16.8822L192.103 17.2386C191.683 17.3099 191.157 17.5712 191.131 18.3079Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M174.443 17.3503C174.499 17.6794 174.666 18.3377 174.666 18.3124C174.89 20.2112 180.231 20.2365 180.511 18.363L180.455 13.2235C180.008 11.4512 174.806 11.4765 174.611 13.3501C174.303 14.6159 174.443 16.0337 174.443 17.3503ZM174.443 24.0595V21.9581C174.415 21.9075 174.415 20.2112 174.387 19.5529L174.499 19.7048C175.338 20.996 177.183 22.2113 179.672 22.11C182.217 22.0341 184.622 21.2745 184.678 18.7428L184.622 12.945C184.566 10.3372 182.049 9.60301 179.532 9.50174C177.071 9.42579 175.254 10.6157 174.443 11.9069L174.415 9.50174H170.22C170.192 14.1855 170.192 18.8947 170.192 23.5785C170.192 23.9329 170.08 24.1861 169.94 24.3886C169.409 25.0216 168.374 26.1356 167.871 26.7938H172.597C173.268 26.0849 174.443 24.9963 174.443 24.0595ZM174.359 19.5529C174.275 19.8061 174.387 19.5276 174.359 19.5529V19.5529Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M163.793 14.489L163.766 13.8095C163.712 11.504 158.384 11.3341 158.437 13.6639C158.437 13.8823 158.437 14.222 158.437 14.489H163.793ZM158.437 15.9451C158.437 16.6003 158.464 17.474 158.437 18.0807C158.384 20.4104 163.712 20.2405 163.766 17.935L163.739 17.571L167.354 17.5468V17.8622C167.301 21.2112 165.078 22.0849 160.847 22.1334C155.626 22.182 154.26 19.6581 154.394 19.0514C154.394 19.0271 154.367 12.7174 154.394 12.6203C154.26 12.0136 155.626 9.48975 160.847 9.53829C165.078 9.58682 167.301 10.509 167.408 13.8823L167.434 15.8237L158.437 15.9451Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M141.635 17.3563C141.701 17.8301 141.848 18.3059 141.848 18.3059C142.072 20.2057 147.427 20.2311 147.708 18.3566L147.68 13.2144C147.231 11.4412 142.016 11.4665 141.792 13.341C141.511 14.6076 141.57 16.8824 141.635 17.3563ZM141.175 20.9657C141.427 20.5604 141.568 20.1551 141.652 19.6991C142.493 20.991 144.343 22.2069 146.81 22.1309C149.362 22.0549 151.773 21.295 151.857 18.7619V12.9611C151.801 10.352 149.306 9.61735 146.782 9.51603C144.315 9.44003 142.493 10.6306 141.652 11.9225V9.92133C141.848 9.18673 142.268 8.83209 142.913 8.65477L150.091 8.40146C150.764 8.42679 150.876 8.3508 151.212 8.22415C152.109 7.81885 152.278 7.23623 152.306 7.10958L152.418 4.60181C152.25 5.03243 152.194 5.56439 151.885 5.91902C151.268 6.29899 151.212 6.34965 150.287 6.5523L143.53 6.4763C142.297 6.50163 140.698 6.45097 139.717 6.85627C139.184 7.05892 138.427 7.36289 138.063 7.84418C137.194 8.93342 137.502 9.76934 137.446 9.74401H137.474L137.446 12.8597V19.2685C137.446 19.5471 137.334 19.8258 137.138 20.0791L135.568 22.0296H140.306C140.334 21.9789 141.007 21.2443 141.175 20.9657Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M131.164 14.0864L131.191 13.7235C131.137 11.4253 125.829 11.2559 125.883 13.5784C125.909 14.2799 125.883 14.9331 125.856 15.6105C125.883 16.2879 125.883 17.3039 125.856 18.0055C125.803 20.3279 131.111 20.1586 131.164 17.8603L131.137 17.5217L134.738 17.4975V17.8361C134.685 21.1505 132.471 22.0456 128.257 22.0939C123.055 22.1423 121.695 19.6264 121.829 19.0216C121.829 18.9248 121.802 12.6833 121.829 12.6107C121.695 12.0059 123.055 9.4899 128.257 9.53829C132.471 9.58667 134.658 10.4092 134.738 13.7961V14.1106H134.711L131.164 14.0864Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.785 4.21631C113.453 4.21631 114.825 5.45715 114.825 6.96563C114.825 8.47411 113.453 9.71495 111.785 9.71495C110.118 9.71495 108.746 8.47411 108.746 6.96563C108.719 5.45715 110.091 4.21631 111.785 4.21631Z"
                fill="#F14343"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.066 14.1012C115.066 15.4813 115.177 16.8865 115.011 18.2665C114.733 20.1485 109.486 20.299 109.07 18.4673C109.098 16.0584 109.098 13.0473 109.098 12.1942C109.07 10.9395 109.153 10.1617 107.71 9.73511H103.046L104.517 11.5919C104.684 11.7927 104.822 12.0436 104.822 12.3949L104.878 18.7433C105.211 21.2776 107.376 22.0304 109.986 22.1308C112.457 22.2061 114.289 21.0267 115.094 19.747C115.427 20.7758 115.621 21.1271 116.427 22.0555H121.118L119.564 20.1234C119.341 19.8725 119.258 19.5964 119.258 19.3204L119.23 12.972L119.208 9.7602H115.029L115.066 14.1012Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M88.0669 13.7746L91.7309 13.7498C91.7856 11.3984 97.2268 11.3737 97.1722 13.7498C97.1175 16.126 97.1995 16.7448 97.1995 16.7448V16.8686C96.8714 16.4231 96.3245 16.0023 95.5042 15.6558C94.6293 15.2597 93.6449 14.8389 92.141 14.7647C89.1333 14.5914 87.0006 16.6953 87.1646 18.8488C87.3287 21.0022 89.0239 21.9675 92.141 22.0913C94.5746 22.1655 96.3792 21.0022 97.1722 19.7151C97.4456 20.7052 97.6917 21.0764 98.512 21.9923H103.133L101.602 20.0864C101.41 19.8636 101.328 19.6161 101.301 19.2943L101.274 18.4032C101.274 17.8092 101.274 12.834 101.274 12.8588C101.41 12.24 100.016 9.64102 94.6839 9.54201C90.3637 9.46775 88.1216 10.3093 88.0396 13.7003V13.7746H88.0669ZM94.1097 16.6953C95.6956 16.6953 97.0081 17.3884 97.0081 18.23C97.0081 19.0715 95.6956 19.7646 94.1097 19.7646C92.5238 19.7646 91.2114 19.0715 91.2114 18.23C91.2114 17.3884 92.5238 16.6953 94.1097 16.6953Z"
                fill="#44456B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.7366 13.8241L74.4006 13.7993C74.4553 11.4479 79.8965 11.4232 79.8418 13.7993C79.7871 16.1755 79.8692 16.7943 79.8692 16.7943V16.9181C79.5411 16.4726 78.9942 16.0518 78.1739 15.7053C77.2989 15.3092 76.3146 14.8884 74.8107 14.7894C71.803 14.6162 69.6702 16.7201 69.8343 18.8735C69.9984 21.0269 71.6936 21.9923 74.8107 22.0913C77.2442 22.1655 79.0489 21.0022 79.8418 19.7398C80.1153 20.7299 80.334 21.1012 81.1816 22.017H85.8026L84.2714 20.1111C84.1073 19.8883 83.998 19.6161 83.9706 19.319L83.9433 18.4032V12.8588C84.08 12.24 82.6855 9.64102 77.3536 9.54201C73.0334 9.46775 70.7913 10.3093 70.7093 13.7003L70.7366 13.8241ZM76.7794 16.7448C78.3653 16.7448 79.6778 17.4379 79.6778 18.2795C79.6778 19.121 78.3653 19.8141 76.7794 19.8141C75.1935 19.8141 73.8811 19.121 73.8811 18.2795C73.8811 17.4379 75.1935 16.7448 76.7794 16.7448Z"
                fill="#F14343"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// Composables
import useScroll from '~/composables/dom/useScroll';

defineOptions({
  name: 'OHeaderStatic',
});

useScroll(0, 'header-scrolled', 1000);
</script>
