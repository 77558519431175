import type { TWebsiteConfig } from '~/typings/types/website-config.types';

/**
 * @description Default website config
 * @returns {TWebsiteConfig}
 */
export default function useWebsiteConfig(): TWebsiteConfig {
  return {
    modes: {
      isProduction: useRuntimeConfig().public.BUILD_MODE === 'production',
      isDevelopment: useRuntimeConfig().public.BUILD_MODE === 'development',
    },
    menusState: {
      desktop: false,
      mobile: false,
    },
    dialogs: {
      forms: {
        state: false,
        requiredForm: null,
      },
    },
  };
}
