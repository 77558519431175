<template>
  <section class="o-hero-default o-hero-default--dark">
    <div class="o-hero-default__wrapper">
      <slot name="image"></slot>

      <div class="container o-hero-default__container">
        <h1 class="o-hero-default__title">
          <slot name="title"></slot>
        </h1>

        <div class="o-hero-default__content">
          <div class="o-hero-default__text">
            <h2 class="o-hero-default__subtitle">
              <slot name="subtitle"></slot>
            </h2>

            <div class="o-hero-default__description">
              <slot name="description"></slot>
            </div>
          </div>

          <div class="o-hero-default__button">
            <slot name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
