import * as Sentry from '@sentry/nuxt';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // console.log(error, 'errorHandler');
  };

  nuxtApp.hook('vue:error', (error, context, info) => {
    Sentry.captureException(error);
    // console.log(error, 'vue:error');
  });
});
