/**
 * @description Toggle Body CSS class composable
 * @returns {{toggleBodyClass: (addRemoveClass: ("add" | "remove"), className: string) => void}}
 */
export default function () {
  function toggleBodyClass(addRemoveClass: 'add' | 'remove', className: string) {
    if (process.client) {
      const { body } = document;

      addRemoveClass === 'add' ? body.classList.add(className) : body.classList.remove(className);
    }
  }

  return {
    toggleBodyClass,
  };
}
