import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const yandexMetrika = useScript({"key":"yandexMetrika","src":"/scripts/yandex-metrika.js","defer":true}, { use: () => ({ yandexMetrika: window.yandexMetrika }) })
    return { provide: { $scripts: { yandexMetrika } } }
  }
})