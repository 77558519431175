import type { IUseApiBase } from '~/composables/api/base/useApiBase.types';

/**
 * @description Composable функция для удобного получения базовых данных для работы с API.
 * @returns {IUseApiBase}
 */
export default function useApiBase(): IUseApiBase {
  const runtimeConfig = useRuntimeConfig();

  const baseUrl = runtimeConfig.app.cdnURL ? runtimeConfig.app.cdnURL : runtimeConfig.app.baseURL;
  const baseApiUrl = import.meta.server
    ? runtimeConfig.API_SERVER_URL
    : runtimeConfig.public.API_CLIENT_URL;

  return {
    baseUrl,
    baseApiUrl,
  };
}
